<template>
<CSLayout :type="'qna'">
  <div class="qna-write-content flex flex-col items-center">
    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">상담분류</p>
      <div class="input-wrapper">
        <v-select
          v-model="category"
          :from="options"
          :filterable="false"
          placeholder="상담 분류 선택"
          as="title::id"
          :multiple="false" />
      </div>
    </div>

    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">제목</p>
      <div class="flex-1">
        <Input v-model="title" placeholder="제목를 입력해주세요." />
      </div>
    </div>

    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">문의내용</p>
      <div class="flex-1">
        <Textarea v-model="content" />
      </div>
    </div>

    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">첨부파일</p>
      <div class="input-wrapper">
        <FileInput :hasLabel="false" :onChange="onFilesChange" />
      </div>
    </div>

    <div class="button-group flex flex-row items-center">
      <button @click="onSubmit" class="save">
        {{ buttonText }}
      </button>
      <button @click="goBack">목록으로</button>
    </div>
  </div>
</CSLayout>
</template>

<script>
import CSLayout from '@/components/CSLayout.vue';
import Input from '@/components/Input.vue';
import Textarea from '@/components/Textarea.vue';
import FileInput from '@/components/FileInput.vue';
import services from '@/services';

export default {
  name: 'QnaWrite',
  components: {
    CSLayout,
    Input,
    Textarea,
    FileInput,
  },
  data() {
    return {
      category: null,
      title: '',
      content: '',
      files: [],
      options: [],
      isEdit: false,
    }
  },
  mounted() {
    this.getCategory();

    const {id} = this.$route.params;
    if (id) {
      this.isEdit = true;
      this.getDetail(id);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getCategory() {
      const data = await services.qnaCategory();
      this.options = data;
    },
    async getDetail(id) {
      const {title, contents, hasQnaCategory} = await services.qnaDetail(id);
      this.title = title;
      this.content = contents;

      if (hasQnaCategory) {
        this.category = hasQnaCategory;
      }
    },
    onFilesChange: function(data) {
      this.files = data;
    },
    async onSubmit() {
      if (!this.category) {
        this.showError('상담분류를 선택해주세요');
        return;
      } else if (!this.title) {
        this.showError('제목을 입력해주세요');
        return;
      } else if (!this.content) {
        this.showError('문의내용을 입력해주세요');
        return;
      }

      const data = {
        categoryId: this.category.id,
        title: this.title,
        contents: this.content,
        qnaFiles: this.files
      }
      
      if (this.isEdit) {
        const {id} = this.$route.params;
        const success = await services.qnaUpdate(id, data);

        if (success) {
          this.$toast.open('문의가 수정되었습니다');
          this.$router.go(-1);
        } else {
          this.showError('문의등록이 실패했습니다');
        }
      } else {
        const success = await services.qnaCreate(data);

        if (success) {
          this.$toast.open('문의가 등록되었습니다');
          this.$router.go(-1);
        } else {
          this.showError('문의등록이 실패했습니다');
        }
      }
    },
    showError(message) {
      this.$toast.open({message, type: 'error'});
    },
  },
  computed: {
    buttonText() {
      return this.isEdit ? '수정하기' : '등록하기'
    },
  },
}
</script>

<style scoped lang="scss">
.qna-write-content {
  .item {
    padding: 15px 0;

    .label {
      width: 120px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.84;
      letter-spacing: -0.8px;
      text-align: left;
      color: #353535;
    }

    .input-wrapper {
      min-width: 268px;
    }
  }

  .button-group {
    margin-top: 57px;

    button {
      width: 192px;
      height: 55px;
      background-color: #c7c7c7;

      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.84;
      letter-spacing: -0.8px;
      text-align: center;
      color: #fff;

      &:first-child {
        margin-right: 16px;
      }

      &.save {
        background-color: #283aef;
      }
    }
  }
}

@media (max-width: 639px) {
  .qna-write-content {
    .item {
      flex-direction: column;
      .label {
        width: auto;
        margin-right: auto;
        margin-bottom: 15px;
        font-size: 15px;
      }
    }
    .button-group {
      flex-direction: column;
      button {
        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>